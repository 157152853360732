<script setup>
import { inject } from "vue";

const loading = inject("loading");
</script>

<template>
  <tbody v-if="!loading" class="container-empty">
    <section>
      <slot />
    </section>
  </tbody>
</template>

<style scoped lang="scss">
.container-empty {
  width: 100%;
  height: 400px;
  section {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;

    display: grid;
    gap: 12px;
    place-items: center;

    color: var(--gray-200);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.3px;
    white-space: pre-line;
  }
}
</style>
