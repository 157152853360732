<script setup>
import { ExportDescription, ExportTitle } from "../base-components";
import { computed, ref, watch } from "vue";

const props = defineProps({
  email: String,
});

const emit = defineEmits(["update:email"]);

const email = ref(props.email);

const isValidEmail = computed(() => validateEmail(email.value));

function validateEmail(email) {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
}

watch(email, (newEmail) => {
  emit("update:email", isValidEmail.value ? newEmail : "");
});
</script>

<template>
  <div>
    <ExportTitle class="title"> {{ $t("opportunities.export.exportEmail.title") }} </ExportTitle>

    <ExportDescription>
      {{ $t("opportunities.export.exportEmail.description") }}
    </ExportDescription>

    <input
      type="email"
      v-model="email"
      id="modalprofit_inputemail_exportacao"
      placeholder="email@email"
      class="input-email-export"
      :class="{ valid: isValidEmail }"
    />
  </div>
</template>

<style lang="scss" scoped>
.title {
  padding: 0 50px;
}

.invalid {
  color: var(--gray-100) !important;
}

.input-email-export {
  height: var(--h-12);
  width: min-content;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;

  padding: 0 !important;

  transition: all 0.2s !important;

  cursor: pointer;
  outline: none;

  font-size: 28px !important;
  font-weight: var(--weight-extrabold);
  text-align: center;

  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #e5e7e7 !important;

  &:focus-visible,
  &:hover {
    outline: none !important;
    border-color: var(--gray-50) !important;
  }

  &::placeholder {
    font-size: 28px !important;
    color: #f0f2f5;
  }
}
</style>
