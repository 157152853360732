<script setup>
import {
  GrModal,
  GrModalContent,
  GrModalFooter,
  GrButton,
  GrModalHeader,
} from "@/components/GrComponents";
import { GrInput } from "@/components/GrComponents/input";
import { GrTextarea } from "@/components/GrComponents/textarea";
import questionIcon from "@/assets/img/icons/opportunities/question.svg";
import { useStore } from "@/utils/useStore";
import { computed, ref, inject } from "vue";

import { useModal } from "@/composables/useModal";
import { useToast } from "@/utils/useToast";

import { useI18n } from "@/utils/useI18n";
const i18n = useI18n();

const { toast } = useToast();
const { getModalState, closeModal } = useModal();

const modalName = "modal-edit-question-global";
const modalState = getModalState(modalName);

const store = useStore();

const loading = ref(false);

const emit = defineEmits("updated");

const copyData = computed(() => {
  return { ...information.value };
});

const setCopyDataQuestion = computed({
  get() {
    return copyData.value.question;
  },
  set(value) {
    copyData.value.question = value;
  },
});

const setCopyDataAnswer = computed({
  get() {
    return copyData.value.answer;
  },
  set(value) {
    copyData.value.answer = value;
  },
});

async function validate() {
  loading.value = true;

  const result = await store.dispatch("questions/updateQuestionAnswer", copyData.value);
  console.log({ result });

  loading.value = false;

  if (result.notValidate) {
    toast(i18n.t(result.message), {
      title: "Greenn Profit",
      variant: "danger",
    });

    return;
  }

  return result;
}

async function onSubmit() {
  const result = await validate();
  if (!result) return;

  emit("updated", result);
  closeModal("modal-edit-question-global");

  toast(i18n.t("opportunities.modal.update-question.success-update"), {
    title: "Greenn Profit",
    variant: "success",
  });
}

const information = inject("questionSelected");
</script>

<template>
  <GrModal :model-value.sync="modalState">
    <GrModalContent size="sm">
      <GrModalHeader />

      <div class="texts-info">
        <div class="icon-question">
          <img :src="questionIcon" alt="question" />
        </div>

        <h2>{{ $t("opportunities.modal.update-question.title") }}</h2>
        <p>{{ $t("opportunities.modal.update-question.description") }}</p>
      </div>

      <div class="inputs-container">
        <GrInput
          name="input-question"
          :modelValue.sync="setCopyDataQuestion"
          type="text"
          placeholder="Qual seria a pergunta?*"
        />

        <GrTextarea
          name="input-response"
          :modelValue.sync="setCopyDataAnswer"
          placeholder="Escreva a resposta*"
          class="textarea-input"
        />
      </div>

      <GrModalFooter>
        <GrButton class="variant-button" variant="green-dark" @click="onSubmit" :loading="loading">
          {{ $t("opportunities.modal.update-question.save") }}
        </GrButton>
      </GrModalFooter>
    </GrModalContent>
  </GrModal>
</template>

<style lang="scss" scoped>
.variant-button {
  width: 100%;
}

h2,
p {
  font-family: Plus Jakarta Sans;
}

.texts-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h2 {
    font-size: var(--font-xl);
    font-weight: var(--weight-bold);
    line-height: var(--spacing-7);
    color: var(--primary-800);
    margin-bottom: var(--spacing-4);
  }

  p {
    font-weight: var(--weight-normal);
    font-size: var(--font-sm);
    color: var(--gray-200);
    line-height: 21px;
    text-align: center;
    white-space: pre-line;
  }

  .icon-question {
    margin-bottom: var(--spacing-9);
    background-color: var(--gray-10);
    border-radius: var(--radius-full);
    padding: var(--spacing-5-6);

    img {
      width: var(--w-6);
      height: var(--w-6);
    }
  }
}

.inputs-container {
  width: 100%;
  margin-top: 20px;
}
</style>

<style lang="scss">
.textarea-input {
  margin-bottom: 0 !important;
  .gr-input {
    margin-bottom: 0 !important;
  }
}
</style>
