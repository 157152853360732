<script setup>
import { useModal } from "@/composables/useModal";
import { computed, inject } from "vue";

const props = defineProps({
  question: {
    type: Object,
    default: {},
  },
  index: {
    type: Number,
    required: true,
  },
});

const { openModal } = useModal();

const computedQuestion = computed(() => props.question.question);

const computedAnswer = computed(() => props.question.answer);

const showAnswer = computed(() => selectedAnswer.value == props.index);

function setSelectedAnswer() {
  selectedAnswer.value = props.index;
}

function setSelected() {
  getSetModalVelue.value = props.question;
  openModal("modal-edit-question-global");
}

function deletQuestion() {
  getSetModalDelet.value = props.question;
  openModal("modal-delete-question");
}

const getSetModalVelue = inject("getSetModalVelue");
const getSetModalDelet = inject("getSetModalDelet");

const selectedAnswer = inject("selectedAnswer");
</script>
<template>
  <div class="separator">
    <div class="star">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.8873 0.655826C6.95775 0.343959 7.40211 0.343958 7.47256 0.655825L8.2557 4.12284C8.30136 4.32496 8.53331 4.42104 8.70851 4.3104L11.7138 2.41262C11.9842 2.2419 12.2984 2.55612 12.1277 2.82645L10.2299 5.83177C10.1192 6.00698 10.2153 6.23892 10.4174 6.28458L13.8845 7.06772C14.1963 7.13817 14.1963 7.58253 13.8845 7.65298L10.4174 8.43612C10.2153 8.48178 10.1192 8.71373 10.2299 8.88893L12.1277 11.8943C12.2984 12.1646 11.9842 12.4788 11.7138 12.3081L8.70851 10.4103C8.53331 10.2997 8.30136 10.3957 8.2557 10.5979L7.47256 14.0649C7.40211 14.3767 6.95775 14.3767 6.8873 14.0649L6.10416 10.5979C6.0585 10.3957 5.82656 10.2997 5.65135 10.4103L2.64603 12.3081C2.3757 12.4788 2.06149 12.1646 2.2322 11.8942L4.12998 8.88893C4.24062 8.71373 4.14454 8.48178 3.94242 8.43612L0.475406 7.65298C0.163539 7.58253 0.163538 7.13817 0.475405 7.06772L3.94242 6.28458C4.14454 6.23892 4.24062 6.00698 4.12998 5.83177L2.2322 2.82646C2.06149 2.55612 2.3757 2.24191 2.64603 2.41262L5.65135 4.3104C5.82656 4.42104 6.0585 4.32496 6.10416 4.12284L6.8873 0.655826Z"
          fill="#00B07F"
        />
      </svg>
      <div class="line" :class="{ 'line-full': showAnswer }"></div>
    </div>
    <div class="full" @click.stop="setSelectedAnswer">
      <div class="title-actions">
        <span class="name pointer">{{ computedQuestion }}</span>
        <div class="actions">
          <img
            class="pointer"
            @click.stop="setSelected"
            src="@/assets/img/icons/opportunities/pencil.svg"
          />
          <img
            class="pointer"
            @click.stop="deletQuestion"
            src="@/assets/img/icons/opportunities/trash.svg"
          />
        </div>
      </div>
      <p class="answer" data-anima="top" v-show="showAnswer">{{ computedAnswer }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.separator {
  display: flex;
  margin-top: var(--spacing-8);
  gap: var(--spacing-10)
}

.star {
  width: var(--spacing-12);
  align-content: center;
  display: flex;
  flex-flow: column;
  gap: var(--spacing-1-5);
  align-items: center;
  svg {
    min-height: var(--h-4);
  }
}
.line {
  width: 2px;
  min-height: var(--h-3);
  border-radius: var(--radius-full);
  background: linear-gradient(180deg, rgba(10, 191, 137, 1) 15%, rgba(253, 187, 45, 0) 100%);
}
.line-full {
  height: var(--h-full);
}
.full {
  width: 95%;
}
.title-actions {
  display: flex;
  justify-content: space-between;

  .actions {
    display: flex;
    gap: var(--spacing-4);
    justify-content: space-between;

    img {
      width: var(--w-4);
      height: var(--h-4);
    }
  }

  .name {
    color: var(--old-800);
    font-weight: var(--weight-semibold);
    font-size: 14px;
  }
}

.answer {
  color: var(--gray-200);
  font-size: 15px;
  line-height: 24px;
}

.pointer {
  cursor: pointer;
}
</style>
