<script setup>
import { useStore } from "@/utils/useStore";
import { computed, onMounted } from "vue";
import { money } from "@/utils/money";
import { GrButton } from "@/components/GrComponents";

const store = useStore();
const emit = defineEmits(["ative"]);

function greennProftActive() {
  store.dispatch("opportunities/setGreennProftActive", 1);
  emit("ative");
}

const getLosOfGreennSoft = computed({
  get() {
    return store.getters["opportunities/getLosOfGreennSoft"];
  },
});

onMounted(() => {
  if (getLosOfGreennSoft.value == "") {
    store.dispatch("opportunities/getLossOfGreennSofyValue");
  }
});
</script>

<template>
  <div>
    <header>
      <h2 class="title-greenn-profit">Greenn Profit</h2>
      <p class="description-greenn-profit">
        {{ $t("opportunities.view.proft.description") }}

        <span v-if="getLosOfGreennSoft > 0">
          {{ $t("opportunities.view.proft.description_potential") }}
          <span> {{ money(getLosOfGreennSoft, $t("opportunities.dashbord.value")) }} </span>
        </span>
      </p>
    </header>
    <div class="container-active-feature">
      <img src="@/assets/img/icons/opportunities/box.png" alt="" />
      <p class="header-information">{{ $t("opportunities.view.proft.greenn_proft") }}</p>
      <span class="proft-information"> {{ $t("opportunities.view.proft.help_info") }} </span>
      <GrButton id="profit_btn_ativar" @click="greennProftActive()" class="active-proft">
        {{ $t("opportunities.view.proft.button") }}</GrButton
      >
    </div>
  </div>
</template>
700
<style lang="scss" scoped>
.description-greenn-profit {
  line-height: var(--spacing-6) !important;
}

.title-greenn-profit {
  font-weight: var(--weight-extrabold);
}

header {
  margin-top: var(--spacing-13);
  h2 {
    color: var(--old-800);
    font-weight: var(--weight-extrabold);
    margin-bottom: var(--spacing-4);
  }
  p {
    white-space: pre-line;
  }
  p,
  span {
    max-width: 65%;
    color: var(--gray-200);
    font-size: var(--font-base);
    line-height: var(--spacing-4-26);
    font-weight: var(--weight-normal);
  }
}
.container-active-feature {
  margin-top: var(--spacing-20);
  width: var(--w-full);
  height: var(--h-full);
  display: grid;
  justify-items: center;
  img {
    width: var(--w-48);
    height: var(--h-48);
    margin-bottom: var(--spacing-6);
  }
  .header-information {
    color: var(--old-800);
    font-weight: var(--weight-bold);
    font-size: var(--font-lg);
    line-height: var(--spacing-6) !important;
    margin-bottom: var(--spacing-5);
  }
  .proft-information {
    max-width: var(--w-98);
    color: var(--gray-200);
    font-weight: var(--weight-base);
    font-size: var(--font-base);
    line-height: var(--spacing-6) !important;
    text-align: center;
    margin-bottom: var(--spacing-11);
    line-height: var(--spacing-6);
  }

  .active-proft {
    width: var(--w-80);
    height: var(--h-14);
    background-color: var(--primary-600);
    &:hover {
      background-color: var(--primary-600);
    }
  }
}
</style>
