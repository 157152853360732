<script setup>
import { computed, onMounted, provide, ref } from "vue";
import { GrButton } from "@/components/GrComponents";
import { useStore } from "@/utils/useStore";
import QuestionGrup from "./QuestionGrup.vue";
import ModalEditQuestionGlobal from "./ModalEditQuestionGlobal.vue";
import ModalDeleteQuestion from "./ModalDeleteQuestion.vue";

const store = useStore();
//variables
const loadingList = ref(false);
const selected = ref(false);
const modalOpen = ref(false);
const modalOpenDelet = ref(false);
const questionSelected = ref({});

const showButtonMore = ref(false);

const listProductisQuestions = computed({
  get() {
    return store.getters["questions/getQuestionsList"];
  },
});

const getSetModalVelue = computed({
  get() {
    return modalOpen.value;
  },
  set(value) {
    questionSelected.value = value;
    modalOpen.value = !modalOpen.value;
  },
});

const getSetModalDelet = computed({
  get() {
    return modalOpenDelet.value;
  },
  set(value) {
    questionSelected.value = value;
    modalOpenDelet.value = !modalOpenDelet.value;
  },
});

const questionShow = computed({
  get() {
    return selected.value;
  },
  set(value) {
    selected.value = value === selected.value ? false : value;
  },
});
const getPagination = computed(() => store.getters["questions/getPagination"]);

async function nextPage() {
  await store.dispatch("questions/setNextPage");
  store.dispatch("questions/getQuestionList", getPagination.value);
}
async function editInList(questionEdited) {
  // Não sei pq mais precisou desse true e false para atualizar o v-for
  loadingList.value = true;
  await store.dispatch("questions/updateUniqueQuestion", questionEdited);
  loadingList.value = false;
}

async function getQuestionList() {
  if (!listProductisQuestions.value.length) {
    await store.dispatch("questions/getQuestionList");
  }

  showButtonMore.value = true;
}

provide("questionShow", questionShow);
provide("questionSelected", questionSelected);
provide("getSetModalVelue", getSetModalVelue);
provide("getSetModalDelet", getSetModalDelet);

onMounted(async () => {
  await getQuestionList();
});
</script>
<template>
  <div>
    <div v-if="!loadingList" class="space-top question-item">
      <div
        v-for="(item, index) in listProductisQuestions"
        :key="index"
        :class="{ 'last-item': listProductisQuestions.length - 1 == index }"
      >
        <QuestionGrup
          :lestChild="listProductisQuestions.length - 1 == index"
          :information="item"
          :index="index"
        />
      </div>
      <div
        v-if="getPagination.currentPage < getPagination.totalPages && showButtonMore"
        class="center-content"
      >
        <GrButton variant="gray-light" @click="nextPage()">
          {{ $t("opportunities.questions_answer.more_loading") }}
        </GrButton>
      </div>
    </div>

    <ModalEditQuestionGlobal @updated="editInList($event)" />
    <ModalDeleteQuestion :is-global="true" :selected-question="questionSelected" />
  </div>
</template>

<style lang="scss" scoped>
.space-top {
  padding-top: var(--spacing-5) !important;
}
.center-content {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.last-item {
  .content {
    padding-bottom: 0 !important;
  }
}
</style>
