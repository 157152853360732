import { render, staticRenderFns } from "./TableEmpty.vue?vue&type=template&id=00f5f19d&scoped=true"
import script from "./TableEmpty.vue?vue&type=script&setup=true&lang=js"
export * from "./TableEmpty.vue?vue&type=script&setup=true&lang=js"
import style0 from "./TableEmpty.vue?vue&type=style&index=0&id=00f5f19d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f5f19d",
  null
  
)

export default component.exports