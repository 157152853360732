<script setup>
import { inject, provide } from "vue";

const props = defineProps({
  filters: {
    type: Array,
    Required: true,
  },
});

const setSearch = inject("setSearch");
</script>

<template>
  <div class="grid-itens">
    <div
      class="list-filter"
      v-for="(item, index) in filters"
      :key="index"
      @click.stop="setSearch(item)"
    >
      <span>{{ item.title }}</span>
      <img :src="item.icon" :alt="item.title" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-filter {
  display: flex;
  justify-content: space-between;

  padding: 10px 12px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: rgb(229, 231, 231, 0.35);
  }

  span {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    caret-color: transparent !important;

    color: var(--text);
  }

  img {
    max-width: 19px;
    max-height: 19px;
    caret-color: transparent !important;
  }
}

.grid-itens {
  display: flex;
  flex-flow: column;
  -ms-flex-pack: justify;
  gap: 10px;
  justify-content: space-between;
  height: 100%;
  padding: 22px 14px;
}
</style>
